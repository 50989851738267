import GlobalLayout from "components/page/GlobalLayout";
import Link from "components/page/headers/HeaderLink";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  PageTitle,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { css } from "linaria";
import React from "react";
import { Helmet } from "react-helmet";

const CookieNotice = () => {
  const COOKIES = [
    {
      name: "Meta Pixel",
      description:
        "Facebook Pixel lets you measure, optimize & retarget audiences for your ad campaigns on Facebook & Instagram.",
    },
    {
      name: "Hot-Jar",
      description:
        "Hotjar is a tool that allows you to visualize and map how users engage with your site with interactive heatmaps of their clicks and actions, recordings of their sessions, gathering of their feedback, and more.",
    },
    {
      name: "Microsoft Clarity",
      description:
        "Clarity is a free user behavior analytics tool that helps you understand how users are interacting with your website through session replays and heatmaps.",
    },
    {
      name: "ZoomInfo",
      description:
        "ZoomInfo is primarily used for sales, marketing, and talent acquisition. The platform provides users with access to contact information and company profiles, which can be used to research potential customers and create targeted lists. ",
    },
    {
      name: "HubSpot",
      description:
        "In addition to tracking page views, the HubSpot tracking code allows you to identify visitors, track events, and manually track page views without reloading the page. ",
    },
    {
      name: "Referral Rock",
      description:
        "We collect information from the people who visit our Blog, Newsletter, Website, or App. This refers to information about your computer and your visits, such as your IP address, geographical location, browser type, referral source, length of visit, pages viewed, emails opened, or other computer/visit based information.",
    },
    {
      name: "6Sense Insights Inc",
      description:
        "6sense's technology identifies and associates traffic and activity across IPs, cookies, and devices, accurately matching visitors to accounts, regardless of their location. 6sense audience intelligence leverages firmographic, intent, and predictive data to help revenue teams define their ideal customer profiles and build lists of target accounts to scale personalized marketing and sales campaigns",
    },
    {
      name: "Google Ads Tag",
      description:
        "Google's ad tag lets us see which google ads perform the best by looking at stats like time on page, and audience demographics, and allow for new ads to be sent to site visitions. ",
    },
    {
      name: "Google Tag",
      description:
        "The Google Tag loads and sends information to a third-party server after a page is visted. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "Reddit Ads Pixel",
      description:
        "The Reddit Tag loads and sends information to a third-party server when the Rhombus website loads in your browser. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "IQM Tracking Pixel",
      description:
        "The IQM pixel loads and sends information to a third-party server when the Rhombus website loads in your browser. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "G2 Tracking Pixel",
      description:
        "G2 Track connects to your accounting app to get only relevant software transaction data to map it to the world's most comprehensive SaaS product taxonomy at G2.com.",
    },
    {
      name: "LinkedIn Insight Pixel",
      description:
        "The LinkedIn Insight Pixel loads and sends information to a third-party server when the Rhombus website loads in your browser. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "Google UA Tag",
      description:
        "The Google UA Tag loads and sends information to a third-party server when the Rhombus website loads in your browser. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "SpotX, Inc Tag",
      description:
        "The SpotX, Inc Tag loads and sends information to a third-party server when the Rhombus website loads in your browser. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "Pubmatic, Inc Tag",
      description:
        "The Pubmatic, Inc Tag loads and sends information to a third-party server when the Rhombus website loads in your browser. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "LiveRamp Holdings, Inc Tag",
      description:
        "The LiveRamp Holdings, Inc Tag loads and sends information to a third-party server when the Rhombus website loads in your browser. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "Microsoft Corportation Tag",
      description:
        "The Microsoft Corportation Tag loads and sends information to a third-party server when the Rhombus website loads in your browser. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "Alphabet, Inc Tag",
      description:
        "The Alphabet, Inc Tag loads and sends information to a third-party server when the Rhombus website loads in your browser. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "Telartia Tag",
      description:
        "The Telartia Tag loads and sends information to a third-party server when the Rhombus website loads in your browser. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "Media.Net Tag",
      description:
        "The Media.Net Tag loads and sends information to a third-party server when the Rhombus website loads in your browser. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "Outbrain Tag",
      description:
        "The Outbrain Tag loads and sends information to a third-party server when the Rhombus website loads in your browser. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "Beachfront Media LLC Tag",
      description:
        "The Beachfront Media LLC Tag loads and sends information to a third-party server when the Rhombus website loads in your browser. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "Facebook, Inc Tag",
      description:
        "The Facebook, Inc Tag loads and sends information to a third-party server when the Rhombus website loads in your browser. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "Index Excahnge, Inc Tag",
      description:
        "The Index Excahnge, Inc Tag loads and sends information to a third-party server when the Rhombus website loads in your browser. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "Smartadserver S.A.S Tag",
      description:
        "The Smartadserver S.A.S Tag loads and sends information to a third-party server when the Rhombus website loads in your browser. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "Taboola, Inc Tag",
      description:
        "The Taboola, Inc Tag loads and sends information to a third-party server when the Rhombus website loads in your browser. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "Amazon Technologies, Inc Tag",
      description:
        "The Amazon Technologies, Inc Tag loads and sends information to a third-party server when the Rhombus website loads in your browser. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "Reddit Inc Tag",
      description:
        "The Reddit Inc Tag loads and sends information to a third-party server when the Rhombus website loads in your browser. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "Smaato Inc Tag",
      description:
        "The Smaato Inc Tag loads and sends information to a third-party server when the Rhombus website loads in your browser. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "Telaria Tag",
      description:
        "The Telaria Tag loads and sends information to a third-party server when the Rhombus website loads in your browser. That information enables Rhombus to enhance targeting, increase online conversions, measure campaign performance, track conversions, and build audiences based on behavior.",
    },
    {
      name: "RB2B",
      description:
        "RB2B is a tool that assists in reaching our to page visitors on Linkedin",
    },
  ];

  const containerClass = css`
    background: var(--nuetral-100);
    p {
      font-size: 12px;
    }
  `;

  const tableStyles = css`
    border-collapse: collapse;
    width: 100%;
    margin: 20px 0;
    font-size: 12px;
    th,
    td {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    th {
      background-color: #f2f2f2;
    }
  `;

  return (
    <GlobalLayout>
      <Helmet>
        <title>Cookie Policy | Rhombus</title>
      </Helmet>
      <SectionContainer className={containerClass}>
        <SectionInner style={{ gap: "2.5rem", flexDirection: "column" }}>
          <TextContainer style={{ gap: "1.25rem" }}>
            <PageTitle style={{ textAlign: "center" }}>
              Cookie Preferences
            </PageTitle>
            <MainParagraph>
              We and our third-party providers use cookies and related
              technologies to automatically collect information about your visit
              to our website and other online services for purposes of analyzing
              and optimizing our services, delivering ads, providing content and
              ads that are more relevant, measuring statistics and the success
              of ad campaigns, and detecting and reporting fraud. All cookies
              and related technologies are on by default when you visit our
              website and other online services, unless you are from a
              jurisdiction that requires consent for the use of such
              technologies. Some of these practices may constitute the sale of
              personal data or the use of personal data for targeted advertising
              subject to opt-out rights provided under certain laws. Please
              visit our <Link to="/privacy/">Privacy Statement</Link> for
              additional information about these and other rights that may be
              available to you.
            </MainParagraph>
          </TextContainer>
          <TextContainer>
            <TitleSmall>Cookies & Pixels in use:</TitleSmall>
            <MainParagraph>
              The parties using cookies on this site are listed below.
            </MainParagraph>
            <table className={tableStyles}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {COOKIES.map((cookie, index) => (
                  <tr key={index}>
                    <td>{cookie.name}</td>
                    <td>{cookie.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TextContainer>
        </SectionInner>
      </SectionContainer>
    </GlobalLayout>
  );
};

export default CookieNotice;
